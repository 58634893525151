import React from 'react'
import {Button, Modal} from 'react-bootstrap'
import {Link} from 'gatsby'
import 'components/css/modal.css'
import {Span} from 'components'

function Sales() {
  return (
    <div className="modal-view">
      <Link to="/careers" state={{ modal: false }}>
        <Button className="close" id="close-button">
         <Span aria-hidden="true">x</Span>
        </Button>
      </Link>

      <h2>FULLSTACK SOFTWARE ENGINEER</h2><hr/>
    <br/>
      <Modal.Title><h4>MINIMUM QUALIFICATIONS</h4></Modal.Title>
      <Modal.Body>
        <ul>
          <li>Professional experience in building reliable web apps</li>
          <li>At least 3 + years front-end development experience</li>
          <li>Experience in using ReactJS and Redux </li>
          <li>Experience with third-party libraries and APIs</li>
          <li>Experience working in an agile environment</li>
          <li>Solid understanding of OOP principles, design patterns, data-driven & test-driven development, and software development best practices</li>
          <li>Good understanding of building scalable apps</li>
          <li>Strong UI/UX exposure</li>
        </ul>
      </Modal.Body>
      <a href="mailto: careers@digipay.ph" className="resume"><Button className="resume-button">Send Resume</Button></a>
    </div>
  )
}

export default Sales
